import { Button, Checkbox, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { endOfMonth, startOfMonth } from '@/utils/timeUtil';
import { useMemo } from 'react';
import { useUserAgent } from '../../../utils/userAgent';
import { floor } from '../listModel';
import { MonthTimePicker } from '../overview/MonthTimePicker';
import { useApartmentList } from '../overview/useApartmentList';
import { useOverviewState } from '../overview/useOverviewState';
import { categoryCheckOptions } from './categoryCheckOptions';

export function useControlPanel() {
	const { isMobile, formLayout } = useUserAgent();

	const [stateParams, setStateParams] = useOverviewState();
	const [form] = Form.useForm();
	const formTime = Form.useWatch('time', form);
	const formFloor = Form.useWatch('floor', form);
	const formCategory = Form.useWatch('category', form);
	const formRoomNumber = Form.useWatch('roomNumber', form);
	const time = dayjs(stateParams?.time);

	const { list, ...requestAction } = useApartmentList(
		{
			startTime: formTime && startOfMonth(formTime)?.valueOf(),
			endTime: formTime && endOfMonth(formTime)?.valueOf(),
			roomNumber: formRoomNumber,
			floorNumber: formFloor,
			chargeStatus: '0',
			category: formCategory,
			orderBy: 'checkInTime',
		},
		formCategory?.length > 0,
	);

	const checkBoxOptions = useMemo(() => {
		return categoryCheckOptions.map((i) => {
			i.disabled = false;

			if ((formCategory ?? []).length === 1 && formCategory.includes(i.value)) {
				i.disabled = true;
			}

			return i;
		});
	}, [formCategory]);

	const panel = useMemo(() => {
		return (
			<Form
				className="mb-3 gap-2"
				layout={formLayout}
				form={form}
				initialValues={{
					...stateParams,
					time: time,
				}}
				onValuesChange={(v, all) => {
					setStateParams((pre) => {
						return {
							...pre,
							...all,
							time: all?.time?.valueOf(),
						};
					});
				}}
				onFinish={(values) => {}}
			>
				<MonthTimePicker />

				<Form.Item label="类型" name="category">
					<Checkbox.Group options={checkBoxOptions} />
				</Form.Item>

				<Form.Item label="房间号检索" name="roomNumber">
					<Input />
				</Form.Item>

				<Form.Item label="楼层" name="floor">
					<Select
						style={{ width: 100 }}
						options={floor.map((i) => {
							return {
								label: `${i}楼`,
								value: i.toString(),
							};
						})}
						allowClear={true}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						onClick={() => {
							const clean = {
								floor: undefined,
								roomNumber: undefined,
							};
							form.setFieldsValue(clean);
							setStateParams((pre) => {
								return {
									...pre,
									...clean,
								};
							});
						}}
					>
						重置
					</Button>
				</Form.Item>
			</Form>
		);
	}, [form, stateParams, checkBoxOptions, setStateParams, formLayout, time]);

	return {
		list,
		requestAction,
		panel: panel,
	};
}
